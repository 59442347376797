var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Pedido de Compra #"+_vm._s(_vm.purchase_order.code)+" ")]),_c('v-card-subtitle',[_c('b',[_vm._v("Empresa: ")]),_vm._v(_vm._s(_vm.purchase_order.company.name)+" "),_c('br'),_c('b',[_vm._v(" Fornecedor: ")]),_vm._v(_vm._s(_vm.purchase_order.supplier.name)+" "),(_vm.purchase_order.scheduled_delivery_at)?[_c('br'),_c('b',[_vm._v(" Previsão de entrega: ")]),_vm._v(" "+_vm._s(_vm.$format.dateBr(_vm.purchase_order.scheduled_delivery_at))+" ")]:_vm._e(),(_vm.purchase_order.notes)?[_c('br'),_c('b',[_vm._v("Observações:")]),_vm._v(" "+_vm._s(_vm.purchase_order.notes)+" ")]:_vm._e()],2)],1)],1)],1),_c('h3',{staticClass:"my-6"},[_vm._v("Produtos")]),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.purchase_order.purchase_order_products,"server-items-length":-1,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [(item.product_id)?_c('span',[_vm._v(_vm._s(item.product.name)+" ")]):_vm._e(),(!item.product_id)?_c('span',[_vm._v(_vm._s(item.product_name)+" ")]):_vm._e()]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity))+" ")]}},{key:"item.quantity_remaining",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity_remaining))+" ")]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [_c('app-number-field',{model:{value:(item.received),callback:function ($$v) {_vm.$set(item, "received", $$v)},expression:"item.received"}})]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.receiveAllProduct(index, item)}}},[_c('v-icon',[_vm._v("mdi-package-variant-closed-plus")])],1)]}}],null,true)}),_c('v-btn',{staticClass:"text-capitalize mt-4",attrs:{"text":"","color":"info"},on:{"click":function($event){return _vm.receiveAll()}}},[_vm._v(" Entregar tudo ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('CommentList',{model:{value:(_vm.purchase_order.comments),callback:function ($$v) {_vm.$set(_vm.purchase_order, "comments", $$v)},expression:"purchase_order.comments"}})],1)],1),_c('v-row',{staticClass:"text-end"},[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.back()}}},[_vm._v(" Voltar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.receiveProducts()}}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }